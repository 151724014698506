<template>
  <a-drawer width="35%" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item :label="$t('门类型')" prop="doorType">
        <a-radio-group v-model="form.doorType" button-style="solid">
          <a-radio-button :value="0">{{ $t('自动门') }}</a-radio-button>
          <a-radio-button :value="1">{{ $t('工业门') }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      
      <a-form-model-item :label="$t('设备编码')" prop="devCode">
        <a-input v-model="form.devCode" :placeholder="$t('请输入设备编码')" allow-clear />
      </a-form-model-item>

      <a-form-model-item :label="$t('开始使用时间')" prop="startTime">
        <a-input-number
          v-model="form.startTime"
          :min="0"
          :max="2359"
          :precision="0"
          style="width: 100%"
          :placeholder="$t('请输入时间，如：830表示8:30')"
        />
      </a-form-model-item>

      <a-form-model-item :label="$t('结束使用时间')" prop="endTime">
        <a-input-number
          v-model="form.endTime"
          :min="0"
          :max="2359"
          :precision="0"
          style="width: 100%"
          :placeholder="$t('请输入时间，如：1800表示18:00')"
        />
      </a-form-model-item>
    <!-- 添加停用时间字段 -->
      <a-form-model-item :label="$t('停用时间')" prop="stopTime">
        <a-date-picker
          v-model="form.stopTime"
          :show-time="true"
          format="YYYY-MM-DD HH:mm:ss"
          :placeholder="$t('请选择停用时间')"
          style="width: 100%"
        />
      </a-form-model-item>

      <!-- 图片上传区域 -->
      <template v-for="(item, index) in uploadFields">
        <a-form-model-item :key="index" :label="item.label" :prop="item.field">
          <a-upload
            :action="uploadUrl"
            list-type="picture-card"
            :file-list="fileListMap[item.field]"
            @preview="(file) => handlePreview(file, item.field)"
            @change="(info) => handleChange(info, item.field)"
            :before-upload="beforeUpload"
          >
            <div v-if="fileListMap[item.field].length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">{{ $t('上传图片') }}</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </template>

      <!-- 信号输出时间设置 -->
      <a-form-model-item :label="$t('开门信号时间')" prop="one">
        <a-input-number v-model="form.one" :min="1" :precision="0" style="width: 100%" :placeholder="$t('请输入开门信号输出时间(秒)')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('停止信号时间')" prop="two">
        <a-input-number v-model="form.two" :min="1" :precision="0" style="width: 100%" :placeholder="$t('请输入停止信号输出时间(秒)')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('关门信号时间') " prop="three">
        <a-input-number v-model="form.three" :min="1" :precision="0" style="width: 100%" placeholder="请输入关门信号输出时间(秒)" />
      </a-form-model-item>

      <!-- 添加用户分组选择 -->
      <template v-if="formType === 1">
        <a-form-model-item :label="$t('分组类型')" prop="groupType">
          <a-radio-group v-model="form.groupType" button-style="solid">
            <a-radio-button :value="0">用户</a-radio-button>
            <a-radio-button :value="1">设备</a-radio-button>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item v-if="form.groupType === 1" label="绑定用户分组">
          <a-select
            v-model="form.groupIds"
            mode="multiple"
            placeholder="请选择要绑定的用户分组"
            style="width: 100%"
            allowClear
          >
            <a-select-option v-for="group in groupList" :key="group.id" :value="group.id">
              {{ group.groupName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </template>

      <!-- 已绑定用户列表 -->
      <a-divider>{{ $t('已绑定用户') }}</a-divider>
      <a-list :data-source="checkList" size="small">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-space>
            <span>{{ item.nickname }}</span>
            <a-divider type="vertical" />
            <span>{{ item.mobile }}</span>
          </a-space>
        </a-list-item>
      </a-list>
    </a-form-model>

    <!-- 底部按钮 -->
    <div class="drawer-footer">
      <a-space>
        <a-button type="primary" @click="show">{{ $t('绑定用户') }}</a-button>
        <a-button type="primary" :loading="submitLoading" @click="submitForm">{{ $t('保存') }}</a-button>
        <a-button @click="cancel">{{ $t('取消') }}</a-button>
      </a-space>
    </div>

    <!-- 用户选择弹窗 -->
    <a-modal
      :title="$t('绑定用户')"
      :visible="addressVisible"
      :width="1000"
      :footer="null"
      @cancel="addressVisible = false"
    >
      <address-list ref="addressPage" @ok="changeAddress" />
    </a-modal>

    <!-- 图片预览 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img :alt="$t('预览图片')" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-drawer>
</template>

<script>
import { getDevice, addDevice, updateDevice } from '@/api/door/device'
import { listGroup } from '@/api/door/group'
import AddressList from './addressList'

export default {
  name: 'CreateForm',
  components: {
    AddressList
  },
  data() {
    // 时间格式验证
    const validateTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error($t('请输入时间')))
        return
      }
      const timeStr = String(value)
      const hour = parseInt(timeStr.slice(0, -2)) || 0
      const minute = parseInt(timeStr.slice(-2)) || 0
      
      if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
        callback(new Error($t('请输入有效的时间格式，如：830表示8:30')))
        return
      }
      callback()
    }

    return {
      uploadUrl: 'http://47.245.94.137:7070/admin-api/common/upload',
      submitLoading: false,
      formTitle: '',
      addressVisible: false,
      previewVisible: false,
      previewImage: '',
      checkList: [],
      userId: [],
      
      // 上传字段配置
      uploadFields: [
        // { label: '上升图片', field: 'upLogo' },
        // { label: '下降图片', field: 'downLogo' },
        // { label: '停止图片', field: 'waitLogo' },
        { label: '在线图片', field: 'onlineLogo' },
        { label: '离线图片', field: 'offLogo' }
      ],
      
      // 文件列表Map
      fileListMap: {
        upLogo: [],
        downLogo: [],
        waitLogo: [],
        onlineLogo: [],
        offLogo: []
      },

      form: {
        id: null,
        userIds: [],
        doorType: 0,
        devCode: '',
        startTime: undefined,
        endTime: undefined,
        upLogo: '',
        downLogo: '',
        waitLogo: '',
        onlineLogo: '',
        offLogo: '',
        one: 5,
        two: 5,
        three: 5,
        stopTime: undefined,
        groupType: 0,
        groupIds: []
      },

      rules: {
        doorType: [{ required: true, message: '请选择门类型', trigger: 'change' }],
        devCode: [{ required: true, message: '请输入设备编码', trigger: 'blur' }],
        startTime: [
          { required: true, message: '请输入开始时间', trigger: 'change' },
          { validator: validateTime, trigger: 'change' }
        ],
        endTime: [
          { required: true, message: '请输入结束时间', trigger: 'change' },
          { validator: validateTime, trigger: 'change' }
        ]
      },

      formType: 1,
      open: false,
      groupList: []
    }
  },
  created() {
    this.uploadFields= [
        // { label: this.$t('上升图片'), field: 'upLogo' },
        // { label: this.$t('下降图片'), field: 'downLogo' },
        // { label: this.$t('停止图片'), field: 'waitLogo' },
        { label: this.$t('在线图片'), field: 'onlineLogo' },
        { label: this.$t('离线图片'), field: 'offLogo' }
      ]

      this.rules= {
        doorType: [{ required: true, message: $t('请选择门类型'), trigger: 'change' }],
        devCode: [{ required: true, message: $t('请输入设备编码'), trigger: 'blur' }],
        startTime: [
          { required: true, message: $t('请输入开始时间'), trigger: 'change' },
          { validator: validateTime, trigger: 'change' }
        ],
        endTime: [
          { required: true, message: $t('请输入结束时间'), trigger: 'change' },
          { validator: validateTime, trigger: 'change' }
        ]
      }
  },
  methods: {
    // 图片上传前检查
    beforeUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error($t('只能上传图片文件!'))
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error($t('图片必须小于 2MB!'))
        return false
      }
      return true
    },

    // 处理图片上传
    handleChange(info, field) {
      const fileList = [...info.fileList]
      this.fileListMap[field] = fileList.slice(-1)
      console.log(info)
      if (info.file.status === 'done') {
        if (info.file.response.code === "20001") {
          this.form[field] = info.file.response.data
          this.$message.success(`${info.file.name} ${$t('上传成功')}`)
        } else {
          this.$message.error(`${info.file.name} ${$t('上传失败')}`)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} ${$t('上传失败')}`)
      }
    },

    // 图片预览
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl
      this.previewVisible = true
    },

    // 用户选择回调
    changeAddress(list, userIds) {
      this.checkList = list
      this.userId = userIds
      this.addressVisible = false
    },

    // 打开用户选择
    show() {
      this.addressVisible = true
      this.$nextTick(() => {
        const userIds = this.form.userResultList?.map(e => e.id) || []
        this.$refs.addressPage.init(userIds)
      })
    },

    // 表单重置
    reset() {
      this.formType = 1
      this.checkList = []
      this.userId = []
      this.form = {
        id: null,
        userIds: [],
        doorType: 0,
        devCode: '',
        startTime: undefined,
        endTime: undefined,
        upLogo: '',
        downLogo: '',
        waitLogo: '',
        onlineLogo: '',
        offLogo: '',
        one: 5,
        two: 5,
        three: 5,
        stopTime: undefined,
        groupType: 0,
        groupIds: []
      }
      
      // 重置文件列表
      Object.keys(this.fileListMap).forEach(key => {
        this.fileListMap[key] = []
      })

      this.$nextTick(() => {
        this.$refs.form?.resetFields()
      })
    },

    // 取消操作
    cancel() {
      this.open = false
      this.reset()
    },

    // 新增操作
    async handleAdd() {
      this.reset()
      await this.fetchGroupList()
      this.open = true
      this.formTitle = $t('添加设备')
    },

    // 获取分组列表
    async fetchGroupList() {
      try {
        const res = await listGroup({ type: 0 }) // 获取用户类型的分组
        if (res.code === '20001') {
          this.groupList = res.data || []
        }
      } catch (error) {
        this.$message.error($t('获取用户分组列表失败'))
      }
    },

    // 修改操作
    async handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row?.id || ids
      
      try {
        const response = await getDevice({ id })
        const data = response.data
        
        this.form = { ...data }
        this.checkList = data.userResultList || []
        this.userId = this.checkList.map(item => item.id)
        
        // 设置图片列表
        this.uploadFields.forEach(({ field }) => {
          if (data[field]) {
            this.fileListMap[field] = [{
              uid: `-1-${field}`,
              name: `${field}.png`,
              status: 'done',
              url: data[field]
            }]
          }
        })
        
        this.open = true
        this.formTitle = $t('修改设备')
      } catch (error) {
        this.$message.error($t('获取设备信息失败'))
      }
    },

    // 提交表单
    submitForm() {
      this.form.userId = this.userId
      
      this.$refs.form.validate(async valid => {
        if (!valid) return
        
        this.submitLoading = true
        try {
          const submitData = { ...this.form }
          
          if (this.form.id) {
            await updateDevice(submitData)
            this.$message.success($t('修改成功'))
          } else {
            await addDevice(submitData)
            this.$message.success($t('新增成功'))
          }
          this.open = false
          this.$emit('ok')
        } catch (error) {
          this.$message.error(error.message || $t('操作失败'))
        } finally {
          this.submitLoading = false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.drawer-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}

.ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
<template>
    <page-header-wrapper>
        <a-card :bordered="false">
            <!-- 条件搜索 -->
            <div class="table-page-search-wrapper">
                <a-form layout="inline">
                    <a-row :gutter="48">

                        <template v-if="advanced">
                            <a-col :md="8" :sm="24">
                                    <a-form-item :label="$t('姓名')" prop="nickname">
                                    <a-input v-model="queryParam.nickname" :placeholder="$t('请输入姓名')" allow-clear />
                                </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24">
                                 <a-form-item :label="$t('手机号')" prop="mobile">
                                    <a-input v-model="queryParam.mobile" :placeholder="$t('请输入手机号')" allow-clear />
                                </a-form-item>
                            </a-col>
                        </template>
                        <a-col :md="!advanced && 8 || 24" :sm="24">
                            <span class="table-page-search-submitButtons"
                                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('查询') }}</a-button>
                                <a-button style="margin-right: 8px" @click="resetQuery"><a-icon type="redo" />{{ $t('重置') }}</a-button>
                                <a @click="toggleAdvanced" style="margin-left: 8px">
                                    {{ advanced ? $t('收起') : $t('展开') }}
                                    <a-icon :type="advanced ? 'up' : 'down'" />
                                </a>
                            </span>
                        </a-col>
                    </a-row>
                </a-form>
            </div>



            <!-- 数据展示 -->
            <!-- <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
                :row-selection="{ selectedRowKeys: ids, onChange: onSelectChange }" :pagination="false"
                :bordered="tableBordered">
                <span slot="createTime" slot-scope="text, record">
                    {{ parseTime(record.createTime) }}
                </span>
                <span slot="operation" slot-scope="text, record">
                    <a-divider type="vertical" v-hasPermi="['user:user:edit']" />
                    <a @click="handleEdit(record)" v-hasPermi="['user:user:remove']">
                        <a-icon type="edit" />
                        <template v-if="record.status == 1">
                            禁用
                        </template>
                        <template v-if="record.status == 0">
                            启用
                        </template>
                    </a>
                </span>
            </a-table> -->

            <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
            :row-selection="rowSelection" :pagination="false"
                :bordered="tableBordered">
                <span slot="createTime" slot-scope="text, record">
                    {{ parseTime(record.createTime) }}
                </span>
                <span slot="operation" slot-scope="text, record">
                    <a-divider type="vertical" v-hasPermi="['user:user:edit']" />
                    <a @click="handleEdit(record)" v-hasPermi="['user:user:remove']">
                        <a-icon type="edit" />
                        <template v-if="record.status == 1">
                            {{ $t('禁用') }}
                        </template>
                        <template v-if="record.status == 0">
                            {{ $t('启用') }}
                        </template>
                    </a>
                </span>
            </a-table> 
            <!-- 分页 -->
            <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
                :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
                @showSizeChange="onShowSizeChange" @change="changeSize" />
            <div style="margin-top: 20px; display: flex; justify-content: flex-end;">
                <a-button type="primary" @click="handleOk()">{{ $t('确认') }}</a-button>
            </div>
        </a-card>
    </page-header-wrapper>
</template>
  
<script>
import { pageUser, listUser, delUser, editUserByIdcard, editUserById } from '@/api/user/user'
import { tableMixin } from '@/store/table-mixin'

export default {
    name: 'User',
    components: {
    },
    mixins: [tableMixin],
    data() {
        return {
            editDto: {
                idCard: null,
                status: null
            },
            list: [],
            selectedRowKeys: [],
            selectedRows: [],
            // 高级搜索 展开/关闭
            advanced: false,
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            ids: [],
            idDto: {
                id: null
            },
            loading: false,
            total: 0,
            // 查询参数
            queryParam: {
                mobile: null,
                nickname: null,
                sex: null,
                birthday: null,
                userSign: null,
                isNotice: null,
                status: null,
                idCard: null,
                pageNum: 1,
                pageSize: 10
            },
            columns: [

                {
                    title: this.$t('手机号'),
                    dataIndex: 'mobile',
                    ellipsis: true,
                    align: 'center'
                },

                {
                    title: this.$t('姓名'),
                    dataIndex: 'nickname',
                    ellipsis: true,
                    align: 'center'
                },
                {
                    title: this.$t('注册时间'),
                    dataIndex: 'createTime',
                    scopedSlots: { customRender: 'createTime' },
                    ellipsis: true,
                    align: 'center'
                },
                {
                    title: this.$t('用户状态'),
                    dataIndex: 'statusStr',
                    ellipsis: true,
                    align: 'center'
                },

                {
                    title: this.$t('操作'),
                    dataIndex: 'operation',
                    width: '18%',
                    scopedSlots: { customRender: 'operation' },
                    align: 'center'
                }
            ]
        }
    },
    filters: {
    },
    created() {
        this.getList()
    },
    computed: {

        rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: 'all-data',
            text: this.$t('Select All Data'),
            onSelect: () => {
              this.selectedRowKeys = [...Array(46).keys()]; // 0...45
            },
          },
          {
            key: 'odd',
            text: this.$t('Select Odd Row'),
            onSelect: changableRowKeys => {
              let newSelectedRowKeys = [];
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return false;
                }
                return true;
              });
              this.selectedRowKeys = newSelectedRowKeys;
            },
          },
          {
            key: 'even',
            text: this.$t('Select Even Row'),
            onSelect: changableRowKeys => {
              let newSelectedRowKeys = [];
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return true;
                }
                return false;
              });
              this.selectedRowKeys = newSelectedRowKeys;
            },
          },
        ],
        onSelection: this.onSelection,
      };
    },
    },
    watch: {
    },
    methods: {
         init(ids) {
            this.ids = ids
            this.selectedRowKeys = ids
            console.log("userId",this.ids)
        },
        handleOk() {
            if (this.checkList) {
                this.$emit('ok', this.checkList,this.userIds)
                console.log(this.checkList)
            } else {
                this.$message.info(this.$t('请选择用户'))
            }
        },
        /** 查询用户信息列表 */
        getList() {
            this.loading = true
            pageUser(this.queryParam).then(response => {
                this.list = response.data.records
                this.total = response.data.total
                this.loading = false
                this.list.forEach(element => {
                    if (0 == element.status) {
                        element.statusStr = this.$t('禁用')
                    } else {
                        element.statusStr = this.$t('启用')
                    }
                });
            })
        },
        handleEdit(row) {
            this.idDto.id = row.id
            editUserById(this.idDto).then(
                () => {
                    this.getList()
                }
            )
        },
        handleStop() {
            this.editDto.status = 0
            editUserByIdcard(this.editDto).then(
                () => {
                    this.getList()
                }
            )
        },
        handleStart() {
            this.editDto.status = 1
            editUserByIdcard(this.editDto).then(
                () => {
                    this.getList()
                }
            )
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParam.pageNum = 1
            this.getList()
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.queryParam = {
                nickname: undefined,
                mobile: undefined,
                pageNum: 1,
                pageSize: 10
            }
            this.handleQuery()
        },
        onShowSizeChange(current, pageSize) {
            this.queryParam.pageSize = pageSize
            this.getList()
        },
        changeSize(current, pageSize) {
            this.queryParam.pageNum = current
            this.queryParam.pageSize = pageSize
            this.getList()
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            console.log("1111111",this.selectedRowKeys)
            this.selectedRows = selectedRows
            console.log("2222222",this.selectedRows)
             this.checkList = selectedRows
            this.userIds=this.selectedRowKeys
            // this.ids = this.selectedRows.map(item => item.id)
            this.ids =this.selectedRowKeys
            this.single = selectedRowKeys.length !== 1
            this.multiple = !selectedRowKeys.length
        },
        toggleAdvanced() {
            this.advanced = !this.advanced
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            var that = this
            const ids = row.id || this.ids
            if (row.id) {
                this.ids.push(row.id)
            }
            this.$confirm({
                title: '确认删除所选中数据?',
                onOk() {
                    let data = []
                    that.ids.map(id => data.push({ "id": id }))
                    return delUser(data)
                        .then(() => {
                            that.onSelectChange([], [])
                            that.getList()
                            that.$message.success(
                                this.$t('删除成功'),
                                3
                            )
                        })
                },
                onCancel() { }
            })
        },
        /** 导出按钮操作 */
        handleExport() {
            var that = this
            this.$confirm({
                title: this.$t('是否确认导出?'),
                content: this.$t('此操作将导出当前条件下所有数据而非选中数据'),
                onOk() {
                    that.download('user/user/export', {
                        ...that.queryParam
                    }, `用户信息_${new Date().getTime()}.xlsx`)
                },
                onCancel() { }
            })
        }
    }
}
</script>
  